import { useTranslation } from "react-i18next";
import clsx from "clsx";
import PropTypes from "prop-types";
import styles from "./TextArea.module.scss";

const TextArea = ({
  variant,
  value,
  className,
  placeholder,
  onChange,
  maxLength,
  ...props
}) => {
  const onValueChange = (e) => {
    const newValue = e.target.value;
    if (maxLength && newValue.length > maxLength) {
      return;
    }

    onChange(newValue);
  };
  const { t } = useTranslation();

  return (
    <div
      className={clsx(className, "relative rounded border border-transparent")}
    >
      <textarea
        value={value}
        onChange={onValueChange}
        className={clsx(styles.textarea, styles[variant])}
        placeholder={t(placeholder)}
        {...props}
        style={{ paddingLeft: "1rem" }}
      />
      {!!maxLength && variant !== "note" && (
        <div className="absolute right-3 bottom-3 text-base text-gray-light">
          {value.length}/{maxLength}
        </div>
      )}
    </div>
  );
};

TextArea.propTypes = {
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

TextArea.defaultProps = {
  variant: "default",
  placeholder: "",
  className: "",
};

export default TextArea;
