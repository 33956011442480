import React from "react";
import { Arrow } from "@assets/icons";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const Back = ({ link, className, isReplace, onClick }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const backHandler = async () => {
    if (link) {
      if (isReplace === true) {
        return navigate(link, { replace: true });
      } else {
        return navigate(link);
      }
    }

    if (onClick) {
      return onClick();
    }

    return navigate(-1);
  };

  return (
    <div
      className={clsx(
        "flex flex-row items-center cursor-pointer hover:opacity-70 transition-opacity duration-30",
        className
      )}
      onClick={() => backHandler()}
    >
      <div className="self-stretch">
        <Arrow />
      </div>
      <span className="ml-4 self-center text-dawn-gray font-extrabold">
        {t("common.back")}
      </span>
    </div>
  );
};
export default Back;
