import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

function TrashIcon({className, ...props}) {
  return (
    <svg
      className={clsx('w-7 h-7', className)}
      fill="none"
      viewBox="0 0 22 23"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.154 4.18h-4.015a1.41 1.41 0 01-1.338-.963l-.357-1.07a1.971 1.971 0 00-1.873-1.35H8.428c-.851 0-1.603.541-1.873 1.35l-.357 1.07c-.191.576-.73.964-1.338.964H.846a.846.846 0 000 1.693h1.465l.865 12.974a4.241 4.241 0 004.222 3.949h7.206a4.243 4.243 0 004.221-3.949l.866-12.974h1.463a.846.846 0 000-1.694zM8.16 2.681a.282.282 0 01.268-.193h5.143c.122 0 .23.078.268.192l.356 1.07c.05.15.112.293.182.43H7.62c.07-.138.132-.28.182-.43L8.16 2.68zm8.975 16.053a2.544 2.544 0 01-2.533 2.37H7.397a2.544 2.544 0 01-2.533-2.37L4.006 5.873h.854c.121 0 .24-.015.358-.029.047.008.09.029.14.029H16.64c.05 0 .092-.02.14-.029.118.014.236.029.357.029h.854l-.856 12.861zm-3.032-8.066v5.64a.846.846 0 01-1.693 0v-5.64a.846.846 0 011.693 0zm-4.513 0v5.64a.846.846 0 01-1.693 0v-5.64a.846.846 0 011.693 0z"
      />
    </svg>
  );
}

TrashIcon.propTypes = {
  className: PropTypes.string,
};

TrashIcon.defaultProps = {
  className: 'fill-current text-pepper',
};

export default TrashIcon;
