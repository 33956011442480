import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Inbox, Trash, Archive, Note } from "@assets/icons";
import "react-toastify/dist/ReactToastify.css";

const BaseToast = ({ closeToast, icon, message }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row justify-between items-center bg-dark-gray h-full px-4">
      <div className="flex flex-row items-center">
        {icon}
        <span className="text-white ml-4">{message}</span>
      </div>
      <span
        className="text-primary"
        onClick={closeToast}
        style={{ width: "4rem" }}
      >
        {t("common.close")}
      </span>
    </div>
  );
};

const Info = ({ closeToast, message }) => (
  <BaseToast closeToast={closeToast} message={message} icon={<Inbox />} />
);
const Deleted = ({ closeToast, message }) => (
  <BaseToast
    closeToast={closeToast}
    message={message}
    icon={<Trash className="fill-current text-white" />}
  />
);
const ChatArchived = ({ closeToast, message }) => (
  <BaseToast
    closeToast={closeToast}
    message={message}
    icon={<Archive className="fill-current text-white" />}
  />
);
const Success = ({ closeToast, message }) => (
  <BaseToast
    closeToast={closeToast}
    message={message}
    icon={<Note className="fill-current text-white" />}
  />
);
const PopupError = ({ closeToast, message }) => (
  <BaseToast
    closeToast={closeToast}
    message={message}
    icon={<Note className="fill-current text-red" />}
  />
);

const NotifyByType = {
  info: Info,
  deleted: Deleted,
  chatArchived: ChatArchived,
  success: Success,
  error: PopupError,
};

export const sendNotify = (message, type) => {
  const Component = NotifyByType[type];
  toast(<Component message={message} />);
};

const GlobalNotify = () => (
  <ToastContainer
    toastStyle={{
      background: "transparent",
      padding: 0,
      marginBottom: 50,
      width: 400,
    }}
    bodyStyle={{
      padding: 0,
      margin: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
    }}
    position="bottom-center"
    autoClose={2000}
    closeButton={false}
    hideProgressBar
    newestOnTop
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
);

export default GlobalNotify;
