import { useTranslation } from 'react-i18next';
import { Button, Calendar } from '@components/Form';
import { CustomModal } from '@components/Modals';
import { useState } from 'react';
import { addDays } from 'date-fns';
import PropTypes from 'prop-types';

const DatePickerModal = ({
    isOpened, onSubmit, onToggle, existingDate
}) => {
    const { t } = useTranslation();
    const [endDate, setEndDate] = useState(new Date());

    return (
        <CustomModal openModal={isOpened} setOpenModal={onToggle} className="bg-milk rounded-xl pt-5 pb-5 w-full max-w-110">
            <div className="flex flex-col items-center">
                <span className="text-center font-bold-poppins text-3xl text-dawn-gray tracking-tight px-28">{t('modal.endDate.title')}</span>
                <p className=" text-lg text-center text-dawn-gray text-opacity-60 px-20">
                    {t('modal.endDate.content')}
                </p>
                <div>
                    <div className="flex flex-row items-center">
                        <Calendar
                            className={'rounded-md m-8'}
                            withoutShadow
                            minDate={addDays(new Date(), 1)}
                            value={endDate}
                            onChange={(value) =>
                                setEndDate(value)
                            }
                        />
                    </div>
                </div>
                <Button
                    className="py-4 px-24 rounded-full"
                    variant="edit"
                    onClick={() => onSubmit(endDate)}
                    isDisabled={endDate.getTime() === existingDate.getTime()}
                >
                    <span className="text-white">
                        {t('common.save')}
                    </span>
                </Button>
            </div>
        </CustomModal>
    );
};

DatePickerModal.propTypes = {
    isOpened: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
    existingDate: PropTypes.instanceOf(Date).isRequired
};

export default DatePickerModal;
