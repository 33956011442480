import { gql } from "@apollo/client";
import GraphQlService from "./graphql";

class accountPersonGraphql extends GraphQlService {
  async getAccountPersonByAccountId(accountId) {
    return this.client
      .query({
        query: gql`
            query {
                getAccountPersonByAccountId (
                id: "${accountId}"
              ){
                accountId
                organizationName
                organization {
                  id
                }
              }
            }
          `,
      })
      .then((result) => result?.data?.getAccountPersonByAccountId);
  }

  async updateGender(gender) {
    return this.client
      .mutate({
        mutation: gql`
          mutation {
            updateGender(updateGenderInput:{
              gender: "${gender}"
            }){
              id
            }  
          }
          `,
      })
      .then((result) => result?.data?.updateGender);
  }
  async getAttributesWithStudentByAccountId(accountId) {
    return this.client
      .query({
        query: gql`
              query {
                getAttributesWithStudentByAccountId(accountId:"${accountId}"){
                  attributeName
                  attributeValue
                  studentCount
                }
              }
            `,
      })
      .then((result) => result?.data?.getAttributesWithStudentByAccountId);
  }
  async updateColor(data) {
    return this.client
      .mutate({
        mutation: gql`
            mutation {
              updateColor(updateColorInput:  {
                  id: "${data.id}"
                  color: ${data.color ? `"${data.color}"` : null}
                }) {
                id
                color
              }
            }
          `,
      })
      .then((result) => result?.data?.updateColor);
  }
}
const accountPersonService = new accountPersonGraphql();
export default accountPersonService;
