import * as React from "react";
import clsx from "clsx";

function ArrowIcon({ color, className, ...props }) {
  return (
    <svg
      className={clsx("w-7 h-7", className)}
      fill="none"
      viewBox="3 0 27 27"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23 13.5H5.625M13.5 21.375L5.625 13.5 13.5 5.625"
        stroke={color || "#585550"}
        strokeWidth={2.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ArrowIcon;
